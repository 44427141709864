const LINKS = {
  APP: 'https://riske.risk-enterprise.com/',
  FOR_DEVELOPERS: '/for-developers/',
  GLOSSARY: '/glossary/',
  LOGOUT: 'https://riske.risk-enterprise.com/',
  PRICING: 'https://riske.risk-enterprise.com/',
  RELEASE_NOTES: '/release-notes/',
  SENDGRID: 'https://riske.risk-enterprise.com/',
  SUPPORT: 'https://riske.risk-enterprise.com/',
  STATUS: 'https://riske.risk-enterprise.com/',
  SIGNUP: 'https://riske.risk-enterprise.com/',
};

export default LINKS;
