const STARWARS = {
  'star wars': '/img/search-chewie.gif',
  'darth vader': '/img/search-darth-vader.gif',
  'r2d2': '/img/search-r2d2.gif',
  'chewie': '/img/search-chewie.gif',
  'luke skywalker': '/img/search-luke.gif',
  'yoda': '/img/search-yoda.gif',
};

export default STARWARS;
